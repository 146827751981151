import styled, { createGlobalStyle } from 'styled-components';
import { Link } from 'gatsby';

export const GlobalRootStyle = createGlobalStyle`
    :root {
        --tbwa-yellow: #fdcc09;
        --tbwa-gray:#F5F6F7;
        --tbwa-overlay: rgba(0,0,0,0.8);
        --tbwa-dark-gray:#878686;
        font-size: 20px;
    }

body{
  padding: 0;
  margin: 0;
  font-family: "avertafor-regular" ,"Helvetica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
button{
    border: none;
    border-radius: unset;
}
a{
    text-decoration: unset;
    color: var(--tbwa-yellow);
}
blockquote {
    font-size: 1rem;
    margin-left:0 ;
    padding-left: 1rem;
    border-left: 0.5rem solid var(--tbwa-yellow);
  }
.yellow{
  color: var(--tbwa-yellow);
}
input,textarea{
  font-size: 17px;
}
@keyframes opensidebar {
    0%{
      opacity:0;
      width: 0px;
    }
    100%{
      opacity:1;
      width: 360px;
    }
  }
  

`;

export const UnderlineHeader = styled.h1`
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  padding: 50px 0px;
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 80px;
    height: 5px;
    background: var(--tbwa-yellow);
  }
`;

export const HeroSection = styled.div`
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.7)), url(${props => props.bgImage});
  background-size: cover;
  background-position: top;
  padding: 80px 20px;
  color: white;
  h1 {
    color: var(--tbwa-yellow);
  }
`;

export const UnderLine = styled.div`
  height: 5px;
  width: 80px;
  background: var(--tbwa-yellow);
`;
export const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
`;
export const Button = styled.button`
  background: var(--tbwa-yellow);
  font-size: 18px;
  padding: 10px 20px;
  color: black;
  display: block;
  margin: ${props => (props.left ? 0 : '1rem auto')};
  a {
    color: black !important;
  }
`;
export const ButtonLink = styled(Link)`
  background: var(--tbwa-yellow);
  font-size: 18px;
  padding: 10px 20px;
  color: black;
  text-align: center;
  margin: ${props => (props.left ? 0 : '1rem auto')};
`;
